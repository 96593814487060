'use client';
import { CarSelectionContext } from '@/contexts/CarSelectionContext';
import Image from 'next/image';
import { useContext, useState } from 'react';
import { useStore } from 'zustand';
import { removeWwwFromUrl } from '@/utils';
import ReviewImageDialog from '@/components/PDP/components/ReviewImageDialog';
import { Dialog } from '@/components/ui/dialog';

export default function FeatureImage() {
  const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [selectedRowType, setSelectedRowType] = useState<'image' | 'video'>(
    'image'
  );

  const store = useContext(CarSelectionContext);
  if (!store) throw new Error('Missing CarContext.Provider in the tree');
  const selectedProduct = useStore(store, (s) => s.selectedProduct);
  const featuredImage = selectedProduct?.mainImage;
  const mediaItems = featuredImage ? [{ review_image_url: featuredImage }] : [];

  // If you also want to include the additional product images after the featured image:
  const additionalMediaItems =
    (selectedProduct?.product?.split(',') as string[]).map((x) => ({
      review_image_url: x,
    })) || [];

  // Combine featured image with additional images
  const allMediaItems = [...mediaItems, ...additionalMediaItems.slice(1)];

  const handleDialogClose = () => {
    setReviewDialogOpen(false);
  };

  const handleOnMediaClick = (index: number, rowType: 'video' | 'image') => {
    setSelectedMediaIndex(index);
    setCurrentSlideIndex(index);
    setReviewDialogOpen(true);
    setSelectedRowType(rowType);
  };

  return (
    <>
      <div
        className="cursor-pointer"
        onClick={() => handleOnMediaClick(0, 'image')}
      >
        <Image
          id="featured-image"
          src={removeWwwFromUrl(featuredImage as string) + '?v=1'}
          alt="a car with a car cover on it"
          fill={true}
          className="hidden object-cover lg:block"
          priority
          sizes="(max-width: 768px) 100vw"
        />
        <Dialog open={reviewDialogOpen} onOpenChange={setReviewDialogOpen}>
          {reviewDialogOpen && (
            <ReviewImageDialog
              onClose={handleDialogClose}
              initialImageIndex={selectedMediaIndex}
              mediaItems={allMediaItems}
              rowType={'image'}
            />
          )}
        </Dialog>
      </div>
    </>
  );
}
